import { Locale } from './Translation'

export type Voice = {
  articleId: string
  isPlaying: boolean
}

const AUDIO_URL = process.env.GATSBY_AUDIO_URL ?? ''
export const buildVoiceUrl = (articleId: string, locale: Locale) =>
  `${AUDIO_URL}/${articleId}-${locale === 'ja' ? 'ja' : 'en'}-normal.mp3`

// beta版用
export const voiceArticleIds = [
  'workman_shoes',
  'workman_aegisfusiondown',
  'moto_tsuruya',
  'paagoworks_switch',
  'gals',
  'yasue_imae',
  'nike_alphafly3',
  'newbalance_860v2',
  'mizuno_waverebellionpro2',
  'puma_fast_r_rnitroelite2',
  'kiki2',
  'van_jacket',
  'aeroleather',
  'pyrenex',
  'workman_chefpants',
  'asics_glideridemax',
  'mont_bell_exceloft',
  'workman_premium',
  'asics_magicspeed4',
  'muji_innerwear',
  'orihara',
  'hotmagic',
  'canadagoose',
  'muji_downjacket',
  'adizero_adiospro4',
  'thecontainershop',
  'lesportsac',
  'mirano_midorikawa',
  'mana_kamioka',
  'nakano_ropeway',
  'llbean_coat',
  'domiziano',
  'yuka_yamauchi',
  'workman_innner',
  'newbalance_minimus',
  'hakuyo_miya',
  'arcteryx_arro',
  'setsudan_asicssb',
  'karrimor_ltfoodie',
  'rimowa',
  'lululun',
  'levis_madeinjapan',
  'grid_tec',
  'taion',
  'hiroshi_nittono',
  'apoc_spiber',
  'mizuno_waveprophecymoc',
  'maiearrings',
  'mamechiyo',
  'knuu',
  'proteca',
  'solament',
  'naoki_terashima',
  'tokiqil',
  'mackage',
  'threeeye',
  'millet_breathebarrier',
  'autry',
  'newbalance_m1000',
  'future_shopping',
  'mysteryranch',
  'miki_omori',
  'arknets',
  'badboy',
  'fidelity',
  'ipsa_thetimeraqua',
  'stillbyhand',
  'easedoron',
  '100years_coat',
  'hudsonkutsuten',
  'kankenoutlong',
  'speedia',
  'kose_mensmake',
  'masahito_inoue',
  'peregrine',
  'asics_tights',
  'stateofescape',
  'kanebo_lotion',
  'sorel',
  'organiczoo',
  'kose_sports',
  'onebykose',
  'umbro',
  'newbalance_mt580gb2',
  'marmot_capital',
  'refa',
  'macpac_weka',
  'derittech',
  'newbalance_freshfoamx1080v14',
  'ftnsessions03',
  'satoseni',
  'miki_emb',
  'fukagawa_glass',
  'igarashiseishi',
  'bekkoanryu',
  'reinaibuka',
  'tokyokomon',
  'ikedakasuri',
  'kameya',
  'souki',
  'workman_bazzheat',
  'osharemajo',
  'kangol',
  'newbalance_610',
  'newbalance_996',
  'audio_info',
]
