import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Link } from 'gatsby'

import PauseIcon from '@icons/Pause'
import PlayIcon from '@icons/Play'
import VolumeIcon from '@icons/Volume'
import Loading from '@atoms/Loading'
import { Article } from '@models/Article'
import { useAllArticles } from '@models/Article/useAllArticles'
import { useAllEnglishArticles } from '@models/Article/useAllEnglishArticles'
import { StoreContext } from '@models/Store'
import { buildVoiceUrl } from '@models/Voice'
import { sendEvent } from '@models/Analytics'
import { buildTargetLocalePath } from '@models/Translation'

import * as style from './style.module.sass'

type AnalyticsData = {
  playId: string
  startTime: number
  prevTime: number | undefined
}

const VoicePlayer = () => {
  const { entity, action } = useContext(StoreContext)
  const { setVoiceIsPlaying } = action
  const { voice } = entity
  const isPlaying = voice?.isPlaying || false

  const { pathname } = window.location
  const locale = pathname.startsWith('/en') ? 'en' : 'ja'
  const japanesseArticles = useAllArticles()
  const englishArticles = useAllEnglishArticles()
  const articles = locale === 'ja' ? japanesseArticles : englishArticles
  const articleMap = useMemo(() => {
    const map: { [id: string]: Article } = {}
    articles.forEach(article => (map[article.blogId] = article))
    return map
  }, [articles])

  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [volume, setVolume] = useState(0.5)
  const [isMute, setIsMute] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const audioRef = useRef<HTMLAudioElement>(null)
  const currentArticle = voice && articleMap[voice.articleId]

  // for Analytics
  const [analyticsData, setAnalyticsData] = useState<
    AnalyticsData | undefined
  >()

  const playPauseHandler = () => {
    if (isPlaying) {
      setAnalyticsData(data => {
        if (data === undefined) {
          return undefined
        }

        return { ...data, startTime: Date.now() }
      })
    }
    setVoiceIsPlaying(!isPlaying)
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const audio = audioRef.current
    if (audio === null) {
      return
    }

    const value = parseInt(event.target.value)
    const newTime = (value / 100) * duration
    audio.currentTime = newTime
    setCurrentTime(newTime)
  }

  const onChageVolume = (event: ChangeEvent<HTMLInputElement>) => {
    const audio = audioRef.current
    if (audio === null) {
      return
    }

    const value = parseInt(event.target.value) / 100
    audio.volume = value
    setVolume(value)
    setIsMute(false)
  }

  const onMute = () => {
    const audio = audioRef.current
    if (audio === null) {
      return
    }

    setIsMute(value => {
      if (value) {
        audio.volume = volume
        return false
      }

      audio.volume = 0
      return true
    })
  }

  const onEnd = () => {
    setVoiceIsPlaying(false)
  }

  const close = () => {
    setAnalyticsData(undefined)
    action.setVoiceArticleId(undefined)
  }

  const updateProgress = () => {
    const audio = audioRef.current
    if (audio === null || audio.duration === 0) {
      return
    }

    setCurrentTime(audio.currentTime)
    setDuration(audio.duration)

    setAnalyticsData(data => {
      if (data === undefined || voice === undefined) {
        return undefined
      }

      const currentSeconds = Math.floor(audio.currentTime)
      // 5秒ごとにイベントを送信
      if (currentSeconds % 5 === 0 && currentSeconds !== data.prevTime) {
        sendEvent({
          name: 'play_voice',
          param: {
            play_id: data.playId,
            article_id: voice.articleId,
            start_time: data.startTime,
            audio_time: currentSeconds,
            max_audio_time: Math.floor(audio.duration),
          },
        })
        return { ...data, prevTime: currentSeconds }
      }

      return data
    })
  }

  useEffect(() => {
    const audio = audioRef.current
    if (audio === null) {
      return
    }

    if (voice === undefined || !voice.isPlaying) {
      audio.pause()
      return
    }

    setTimeout(() => {
      audio.play()

      if (analyticsData === undefined) {
        const now = Date.now()
        setAnalyticsData({
          playId: `${entity.user?.userId}_${voice.articleId}_${now}`,
          startTime: now,
          prevTime: undefined,
        })
      }
    }, 500)
  }, [voice])

  useEffect(() => {
    const audio = audioRef.current
    if (audio === null) {
      return
    }

    audio.volume = isMute ? 0 : volume
    audio.addEventListener('timeupdate', updateProgress)
    audio.addEventListener('loadedmetadata', updateProgress)

    return () => {
      audio.removeEventListener('timeupdate', updateProgress)
      audio.removeEventListener('loadedmetadata', updateProgress)
    }
  }, [audioRef, voice])

  useEffect(() => setIsLoading(true), [currentArticle])

  return (
    <div className={style.player} data-show={currentArticle !== undefined}>
      {voice !== undefined && currentArticle !== undefined && (
        <>
          <div className={style.titleContainer}>
            <Link
              to={buildTargetLocalePath(locale, currentArticle.path)}
              className={style.title}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M5.25 12.75L12.75 5.25"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.25 5.25L12.75 5.25V12.75"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div
                className={style.scroll}
                key={`${voice.articleId}_${pathname}`}
              >
                <span
                  style={{
                    animationDuration: `${currentArticle.title.length / 2}s`,
                  }}
                >
                  {currentArticle.title}
                </span>
                <span
                  style={{
                    animationDuration: `${currentArticle.title.length / 2}s`,
                  }}
                >
                  {currentArticle.title}
                </span>
              </div>
            </Link>

            <button onClick={close} className={style.close}>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                <rect
                  width="0.574237"
                  height="10.6234"
                  rx="0.287119"
                  transform="matrix(-0.710066 0.704135 -0.710066 -0.704135 8.75 7.85547)"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0.625"
                />
                <rect
                  width="0.574236"
                  height="10.6234"
                  rx="0.287118"
                  transform="matrix(0.710065 0.704136 -0.710065 0.704136 8.29297 0.490234)"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0.625"
                />
              </svg>
            </button>
          </div>

          <div className={style.ui}>
            {isLoading ? (
              <div className={style.loadingContainer}>
                <Loading />
              </div>
            ) : (
              <button
                onClick={playPauseHandler}
                className={style.playPause}
                data-active={voice.isPlaying}
              >
                {voice.isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>
            )}

            <div className={style.progress}>
              <div className={style.time}>
                <span>{formatTime(currentTime)}</span>
                <span>-{formatTime(duration - currentTime)}</span>
              </div>
              <div className={style.bar}>
                <div
                  className={style.value}
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                />
                <input
                  className={style.slider}
                  type="range"
                  min="0"
                  max="100"
                  value={duration ? (currentTime / duration) * 100 : 0}
                  onChange={onChange}
                  onMouseDown={() => setVoiceIsPlaying(false)}
                  onMouseUp={() => setVoiceIsPlaying(true)}
                />
              </div>
            </div>

            <div className={style.volume}>
              <button onClick={onMute}>
                <VolumeIcon isMute={isMute} />
              </button>
              <div className={style.bar}>
                <div
                  className={style.value}
                  style={{ width: `${isMute ? 0 : volume * 50}px` }}
                />
                <input
                  className={style.slider}
                  type="range"
                  min="0"
                  max="100"
                  value={isMute ? 0 : volume * 100}
                  onChange={onChageVolume}
                />
              </div>
            </div>
          </div>

          <audio
            ref={audioRef}
            title={currentArticle?.title}
            src={buildVoiceUrl(currentArticle.blogId, locale)}
            onEnded={onEnd}
            onLoadedData={() => setIsLoading(false)}
          />
        </>
      )}
    </div>
  )
}

export default VoicePlayer
