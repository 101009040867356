type VolumeIconProps = {
  isMute?: boolean
}
const VolumeIcon = ({ isMute = false }: VolumeIconProps) =>
  isMute ? (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_14389_164800)">
        <path
          d="M15.1484 11.048C15.1484 9.42477 14.2153 8.02512 12.8545 7.34473V9.65805L15.0609 11.8645C15.1192 11.602 15.1484 11.3299 15.1484 11.048Z"
          fill="currentColor"
        />
        <path
          d="M17.4423 11.048C17.4423 11.9909 17.2187 12.8656 16.8591 13.6724L18.2393 15.0526C18.9002 13.8668 19.2793 12.506 19.2793 11.048C19.2793 7.12121 16.5383 3.83591 12.8545 3V4.89537C15.508 5.68268 17.4423 8.1418 17.4423 11.0577V11.048Z"
          fill="currentColor"
        />
        <path
          d="M12.8545 17.2103V19.1057C13.9626 18.853 14.9831 18.3767 15.8774 17.7255L14.5555 16.4036C14.0306 16.7535 13.4668 17.0256 12.8545 17.2103Z"
          fill="currentColor"
        />
        <path
          d="M11.0174 3.70947L8.95679 5.77008L11.0174 7.83068V3.70947Z"
          fill="currentColor"
        />
        <path
          d="M6.43943 8.28764L2.75562 8.29736V13.8085H6.42971L11.0175 18.3963V12.8657L18.1518 20L19.4349 18.717L3.88312 3.16528L2.6001 4.4483L6.43943 8.28764Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14389_164800">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_14389_164837)">
        <path
          d="M2.75 8.2501V13.7501H6.41667L11 18.3334V3.66677L6.41667 8.2501H2.75ZM15.125 11.0001C15.125 9.3776 14.19 7.98427 12.8333 7.30594V14.6851C14.19 14.0159 15.125 12.6226 15.125 11.0001ZM12.8333 2.96094V4.84927C15.4825 5.6376 17.4167 8.09427 17.4167 11.0001C17.4167 13.9059 15.4825 16.3626 12.8333 17.1509V19.0393C16.5092 18.2051 19.25 14.9234 19.25 11.0001C19.25 7.07677 16.5092 3.7951 12.8333 2.96094Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14389_164837">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )

export default VolumeIcon
