// extracted by mini-css-extract-plugin
export var bar = "style-module--bar--6d3fa";
export var close = "style-module--close--90d8a";
export var loadingContainer = "style-module--loadingContainer--2cc6d";
export var playPause = "style-module--playPause--f8e73";
export var player = "style-module--player--72959";
export var progress = "style-module--progress--e6c5c";
export var scroll = "style-module--scroll--36da8";
export var slider = "style-module--slider--b50ea";
export var time = "style-module--time--570ce";
export var title = "style-module--title--4a20b";
export var titleContainer = "style-module--titleContainer--dbe52";
export var ui = "style-module--ui--38b34";
export var value = "style-module--value--b47ad";
export var volume = "style-module--volume--1c9fc";