const PlayIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_14265_76966)">
      <path
        d="M9.53688 5.97801C8.87115 5.55437 8 6.03258 8 6.82167V17.1783C8 17.9674 8.87115 18.4456 9.53688 18.022L17.6742 12.8437C18.2917 12.4507 18.2917 11.5493 17.6742 11.1563L9.53688 5.97801Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_14265_76966">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)

export default PlayIcon
