import * as style from './style.module.sass'

type LoadingProps = {
  design?: 'black' | 'white'
  size?: 'small' | 'middium'
}
const Loading = ({ design = 'black', size = 'middium' }: LoadingProps) => {
  return <div className={style.loading} data-design={design} data-size={size} />
}

export default Loading
