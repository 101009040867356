import { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Locale } from './Translation'

export const isLocal = process.env.GATSBY_LOCAL_ENV === 'true'

type ConvertOption = {
  brString?: string
}
export const removeHtmlTags = (
  htmlString: string,
  convertOption: ConvertOption = {},
) => {
  if (convertOption.brString !== undefined) {
    htmlString = htmlString.replace(/<br[^>]*>/g, convertOption.brString)
  }

  return htmlString.replace(/<[^>]*>/g, '')
}

// Use this hook for reading the siteMetadata definitions in gatsby-config.js.
// Add the fields you need to the query below.
type SiteData = {
  site: {
    siteMetadata: {
      title: string
      description: string
      siteUrl: string
    }
  }
}
export const useSiteMetadata = () => {
  const data = useStaticQuery<SiteData>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return data.site.siteMetadata
}

export const checkNumber = (value: unknown, fallback?: number) => {
  const number = Number(value)
  return isNaN(number) ? fallback : number
}

export const convertPublishDateForDate = (publishDate: string) =>
  // replaceAllの理由 https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
  publishDate.replaceAll('.', '-')

export const PrefectureIndex = {
  北海道: 1,
  青森県: 2,
  岩手県: 3,
  宮城県: 4,
  秋田県: 5,
  山形県: 6,
  福島県: 7,
  茨城県: 8,
  栃木県: 9,
  群馬県: 10,
  埼玉県: 11,
  千葉県: 12,
  東京都: 13,
  神奈川県: 14,
  新潟県: 15,
  富山県: 16,
  石川県: 17,
  福井県: 18,
  山梨県: 19,
  長野県: 20,
  岐阜県: 21,
  静岡県: 22,
  愛知県: 23,
  三重県: 24,
  滋賀県: 25,
  京都府: 26,
  大阪府: 27,
  兵庫県: 28,
  奈良県: 29,
  和歌山県: 30,
  鳥取県: 31,
  島根県: 32,
  岡山県: 33,
  広島県: 34,
  山口県: 35,
  徳島県: 36,
  香川県: 37,
  愛媛県: 38,
  高知県: 39,
  福岡県: 40,
  佐賀県: 41,
  長崎県: 42,
  熊本県: 43,
  大分県: 44,
  宮崎県: 45,
  鹿児島県: 46,
  沖縄県: 47,
}

export const mailToArtisan = (locale: Locale) =>
  locale === 'en'
    ? 'mailto:artisan@zozo.com?subject=Inquireies&amp;body='
    : 'mailto:artisan@zozo.com?subject=問い合わせ&amp;body=ご記入ください'

export const isNotUndefined = (value: unknown) => value !== undefined

export const switchBodyScroll = (enableScroll: boolean) => {
  if (enableScroll) {
    document.body.classList.remove('disableScroll')
  } else {
    document.body.classList.add('disableScroll')
  }
}

export const useScroll = () => {
  const [isTop, setIsTop] = useState(true)
  const lastScrollY = useRef(0)
  const [isScrollingDown, setIsScrollingDown] = useState(false)

  const handleScroll = () => {
    const { scrollY } = window
    setIsTop(scrollY < 100)
    setIsScrollingDown(scrollY > lastScrollY.current)
    lastScrollY.current = scrollY
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return { isTop, isScrollingDown }
}

export const sleep = async (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))

export const buildUtmParam = (params: {
  source: string
  medium: string
  campaign: string
}) =>
  `utm_source=${params.source}&utm_medium=${params.medium}&utm_campaign=${params.campaign}`
