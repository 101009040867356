const PauseIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 3.375C6.25 3.06434 6.52982 2.8125 6.875 2.8125H7.5C7.84518 2.8125 8.125 3.06434 8.125 3.375V14.625C8.125 14.9357 7.84518 15.1875 7.5 15.1875H6.875C6.52982 15.1875 6.25 14.9357 6.25 14.625V3.375Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.875 3.375C11.875 3.06434 12.1548 2.8125 12.5 2.8125H13.125C13.4702 2.8125 13.75 3.06434 13.75 3.375V14.625C13.75 14.9357 13.4702 15.1875 13.125 15.1875H12.5C12.1548 15.1875 11.875 14.9357 11.875 14.625V3.375Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PauseIcon
