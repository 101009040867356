exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-artisan-about-index-tsx": () => import("./../../../src/pages/artisan/about/index.tsx" /* webpackChunkName: "component---src-pages-artisan-about-index-tsx" */),
  "component---src-pages-artisan-articles-index-tsx": () => import("./../../../src/pages/artisan/articles/index.tsx" /* webpackChunkName: "component---src-pages-artisan-articles-index-tsx" */),
  "component---src-pages-artisan-index-tsx": () => import("./../../../src/pages/artisan/index.tsx" /* webpackChunkName: "component---src-pages-artisan-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-history-index-tsx": () => import("./../../../src/pages/history/index.tsx" /* webpackChunkName: "component---src-pages-history-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movie-index-tsx": () => import("./../../../src/pages/movie/index.tsx" /* webpackChunkName: "component---src-pages-movie-index-tsx" */),
  "component---src-pages-preview-index-tsx": () => import("./../../../src/pages/preview/index.tsx" /* webpackChunkName: "component---src-pages-preview-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-redirect-google-index-tsx": () => import("./../../../src/pages/redirect-google/index.tsx" /* webpackChunkName: "component---src-pages-redirect-google-index-tsx" */),
  "component---src-pages-request-index-tsx": () => import("./../../../src/pages/request/index.tsx" /* webpackChunkName: "component---src-pages-request-index-tsx" */),
  "component---src-pages-request-thanks-index-tsx": () => import("./../../../src/pages/request/thanks/index.tsx" /* webpackChunkName: "component---src-pages-request-thanks-index-tsx" */),
  "component---src-pages-save-index-tsx": () => import("./../../../src/pages/save/index.tsx" /* webpackChunkName: "component---src-pages-save-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-series-index-tsx": () => import("./../../../src/pages/series/index.tsx" /* webpackChunkName: "component---src-pages-series-index-tsx" */),
  "component---src-pages-setting-index-tsx": () => import("./../../../src/pages/setting/index.tsx" /* webpackChunkName: "component---src-pages-setting-index-tsx" */),
  "component---src-pages-signin-index-tsx": () => import("./../../../src/pages/signin/index.tsx" /* webpackChunkName: "component---src-pages-signin-index-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-thanks-index-tsx": () => import("./../../../src/pages/thanks/index.tsx" /* webpackChunkName: "component---src-pages-thanks-index-tsx" */),
  "component---src-pages-videos-index-tsx": () => import("./../../../src/pages/videos/index.tsx" /* webpackChunkName: "component---src-pages-videos-index-tsx" */),
  "component---src-templates-all-articles-template-tsx": () => import("./../../../src/templates/AllArticlesTemplate.tsx" /* webpackChunkName: "component---src-templates-all-articles-template-tsx" */),
  "component---src-templates-article-template-index-tsx": () => import("./../../../src/templates/ArticleTemplate/index.tsx" /* webpackChunkName: "component---src-templates-article-template-index-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-feature-child-template-index-tsx": () => import("./../../../src/templates/FeatureChildTemplate/index.tsx" /* webpackChunkName: "component---src-templates-feature-child-template-index-tsx" */),
  "component---src-templates-project-child-template-index-tsx": () => import("./../../../src/templates/ProjectChildTemplate/index.tsx" /* webpackChunkName: "component---src-templates-project-child-template-index-tsx" */),
  "component---src-templates-redirect-template-tsx": () => import("./../../../src/templates/RedirectTemplate.tsx" /* webpackChunkName: "component---src-templates-redirect-template-tsx" */),
  "component---src-templates-series-child-template-index-tsx": () => import("./../../../src/templates/SeriesChildTemplate/index.tsx" /* webpackChunkName: "component---src-templates-series-child-template-index-tsx" */)
}

